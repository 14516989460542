/** @format */

export const ADD_CONTACT = 'ADD_CONTACT';
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS';
export const ADD_CONTACT_ERROR = 'ADD_CONTACT_ERROR';

export const GET_CONTACT = 'GET_CONTACT';
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS';
export const GET_CONTACT_ERROR = 'GET_CONTACT_ERROR';

export const RESET_CONTACT = 'RESET_CONTACT';

export const SEARCH_CONTACTS = 'SEARCH_CONTACTS';
export const SEARCH_CONTACTS_SUCCESS = 'SEARCH_CONTACTS_SUCCESS';
export const SEARCH_CONTACTS_ERROR = 'SEARCH_CONTACTS_ERROR';

export const RESET_SEARCH_CONTACTS = 'RESET_SEARCH_CONTACTS';

export const LIST_ALL_CONTACTS = 'LIST_ALL_CONTACTS';
export const LIST_ALL_CONTACTS_SUCCESS = 'LIST_ALL_CONTACTS_SUCCESS';
export const LIST_ALL_CONTACTS_ERROR = 'LIST_ALL_CONTACTS_ERROR';

export const LIST_USERS_FOR_CONTACT = 'LIST_USERS_FOR_CONTACT';
export const LIST_USERS_FOR_CONTACT_SUCCESS = 'LIST_USERS_FOR_CONTACT_SUCCESS';
export const LIST_USERS_FOR_CONTACT_ERROR = 'LIST_USERS_FOR_CONTACT_ERROR';

export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_CONTACT_SUCCESS = 'UPDATE_CONTACT_SUCCESS';
export const UPDATE_CONTACT_ERROR = 'UPDATE_CONTACT_ERROR';

export const INVITE_CONTACT = 'INVITE_CONTACT';
export const INVITE_CONTACT_SUCCESS = 'INVITE_CONTACT_SUCCESS';
export const INVITE_CONTACT_ERROR = 'INVITE_CONTACT_ERROR';

export const CREATE_PROFILE_CONTACT = 'CREATE_PROFILE_CONTACT';
export const CREATE_PROFILE_CONTACT_SUCCESS = 'CREATE_PROFILE_CONTACT_SUCCESS';
export const CREATE_PROFILE_CONTACT_ERROR = 'CREATE_PROFILE_CONTACT_ERROR';

export const GET_PROFILE_CONTACT = 'GET_PROFILE_CONTACT';
export const GET_PROFILE_CONTACT_SUCCESS = 'GET_PROFILE_CONTACT_SUCCESS';
export const GET_PROFILE_CONTACT_ERROR = 'GET_PROFILE_CONTACT_ERROR';

export const UPDATE_PROFILE_CONTACT = 'UPDATE_PROFILE_CONTACT';
export const UPDATE_PROFILE_CONTACT_SUCCESS = 'UPDATE_PROFILE_CONTACT_SUCCESS';
export const UPDATE_PROFILE_CONTACT_ERROR = 'UPDATE_PROFILE_CONTACT_ERROR';

export const GET_FIELD_DEFINITIONS = 'GET_FIELD_DEFINITIONS';
export const GET_FIELD_DEFINITIONS_SUCCESS = 'GET_FIELD_DEFINITIONS_SUCCESS';
export const GET_FIELD_DEFINITIONS_ERROR = 'GET_FIELD_DEFINITIONS_ERROR';
