/** @format */

import React from 'react';

const NotFound = () => {
  return (
    <div className='text-center'>
      <h1>Page not found</h1>
    </div>
  );
};

export default NotFound;
